@import url("https://fonts.googleapis.com/css?family=Paytone+One");

#root {
  height:100%;
}


body{
  height: 100%;
}


/*navbar active highlights start */
.active{
  color:#00b4d8
}

/*navbar active highlights end*/

/*chevron bottom start*/
.header-down-arrow{
  position:relative;
  bottom: 4vh;
}

.header-down-arrow img{
  animation: fade-slide-up infinite 3s ease-out forwards;
  opacity: 0;
  cursor:pointer;
}

@keyframes fade-slide-up {
  0%{
      opacity:0;
      transform: translateY(-4rem);
  }
  80%{
    opacity:1;
    transform: none;
  }
  100%{
    opacity:0;
    transform: none;
  }
}
/*chevron bottom end*/

#About, #Contact, #Projects, #Resume, #Writing, #Experience{
  position:relative
}

/* wave start */
.TopWave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.TopWave svg {
  position: relative;
  display: block;
  width: calc(137% + 1.3px);
  height: 151px;
}

.TopWave.shape-fill{
  fill: black;
}


.BottomWave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.BottomWave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 145px;
}

.BottomWave.shape-fill {
  fill: #000000;
}




.background{
  fill: #ebf2fa
}

.secondary_background{
  fill: #505c72
}

/* wave end*/



/*drop shadow*/
.shadow{
    filter: drop-shadow( 8px 3px 7px rgba(0, 0, 0, .4));
}

.no-highlight{
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.pointer-hover{
  cursor:pointer;
}

.round{
  border-radius: 15%;
}


.spacer{
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.divider {
  background-image: url('./images/divider.svg');
}





.no_underline{
  text-decoration: none;
}
